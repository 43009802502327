<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>{{ isEdit? 'Edit' : 'Add' }} Widget</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>
      <!-- <v-toolbar
        dark
        color="thingsnesia_base"
      >
        <v-btn
          icon
          dark
          @click="closeDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Add Widget</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="saveWidget()"
          >
            Save
          </v-btn>
        </v-toolbar-items>
      </v-toolbar> -->

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <p>Setting</p>
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :disabled="loading"
            :counter="30"
            outlined
            dense
            label="Name"
          />
          <v-textarea
            v-model="form.description"
            :rules="descriptionRules"
            :disabled="loading"
            :counter="100"
            outlined
            dense
            label="Description"
            auto-grow
          />
          <v-autocomplete
            v-model="form.type"
            :rules="typeRules"
            :items="allTypes"
            :disabled="loading"
            outlined
            dense
            label="Type"
          />
          <v-divider class="mb-4" />

          <p>Data</p>
          <v-autocomplete
            v-model="form.source"
            :rules="sourceRules"
            :items="allSources"
            :disabled="loading"
            outlined
            dense
            label="Source"
          />
          <v-autocomplete
            v-model="form.device"
            :rules="deviceRules"
            :items="devices"
            :disabled="loading"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Device"
          />
          <v-autocomplete
            v-if="form.source !== 'energy'"
            v-model="form.payloadKey"
            :rules="payloadKeyRules"
            :items="payloadKeys"
            :disabled="loading"
            outlined
            dense
            :multiple="needMultiplePayload"
            item-text="name"
            item-value="id"
            label="Payload Key"
          >
            <template
              v-if="needMultiplePayload"
              v-slot:prepend-item
            >
              <v-list-item
                ripple
                @click="toggleSelectAll"
              >
                <v-list-item-action>
                  <v-icon :color="selectAllPayloadKey.length > 0 ? 'indigo darken-4' : ''">
                    {{ selectAllIcon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2" />
            </template>
          </v-autocomplete>

          <div v-if="form.source == 'uplink' || form.source == 'energy'">
            <v-divider class="mb-4" />
            <p>Time</p>
            <template v-if="form.type == 'chart'">
              <v-select
                v-model="form.timeWindow"
                :rules="timeWindowRules"
                :items="optionsTimeWindow"
                outlined
                dense
                label="Time Window"
              />
              <v-select
                v-if="form.source == 'energy'"
                v-model="form.interval"
                :rules="intervalRules"
                :items="optionsInterval"
                outlined
                dense
                label="Interval"
              />
            </template>
            <template v-else>
              <v-select
                v-if="form.source == 'energy'"
                v-model="form.frame"
                :rules="frameRules"
                :items="optionsFrame"
                outlined
                dense
                label="Time Frame"
              />
            </template>
          </div>
        </v-form>

        <v-divider class="mb-4" />
        <div class="d-flex justify-space-between align-center mb-3">
          <span>Properties</span>
          <v-btn
            v-if="form.type && properties"
            small
            icon
            @click="resetToDefaultProperties()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
        <template v-if="form.type">
          <component
            :is="generateComponent(form.type)"
            :type="form.type"
            :properties.sync="properties"
          />
        </template>
        <div v-else>
          <v-alert
            text
            type="info"
          >
            Select type first
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          @click="saveWidget()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import merge from 'lodash/merge';
import EventBus from '@/EventBus';
import * as types from '@/store/mutation-types';
import { isEmptyObject, underscoreToSpace } from '@/utils/utils';

export default {
  name: 'DashboardDetailWidgetForm',
  components: {
    GaugeProperties: () => import('./properties/GaugeProperties'),
    ValueProperties: () => import('./properties/ValueProperties'),
    NullProperties: () => import('./properties/NullProperties'),
  },
  props: {
    dashboardDetail: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    item: {
      // selectedWidget Object
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      allTypes: ['gauge', 'value', 'chart', 'table'],
      // allTypes: ['gauge', 'value', 'chart', 'switch', 'counter', 'progress', 'table', 'map'],
      allSources: [],
      optionsTimeWindow: [
        {
          text: 'This day',
          value: 'today',
        },
        {
          text: 'Latest three days',
          value: '3 days ago',
        },
        {
          text: 'This week',
          value: 'last monday',
        },
        {
          text: 'This month',
          value: 'first day of',
        },
        {
          text: 'This year',
          value: 'first day of January',
        },
      ],
      optionsInterval: [
        {
          text: 'Hourly',
          value: 'hourly',
        },
        {
          text: 'Daily',
          value: 'daily',
        },
        {
          text: 'Monthly',
          value: 'monthly',
        },
      ],
      optionsFrame: [
        {
          text: 'Current Hour',
          value: 'hour',
        },
        {
          text: 'Current Day',
          value: 'day',
        },
        {
          text: 'Current Month',
          value: 'month',
        },
      ],
      devices: [],
      payloadKeys: [],
      dialog: false,
      loading: false,
      formValidity: true,
      isEdit: false,
      form: {
        name: '',
        description: '',
        type: null,
        field: null,
        block: null,
        device: null,
        payloadKey: [],
        source: null,
        timeWindow: null,
        interval: null,
        frame: null,
        multipleDevices: [],
      },
      defaultForm: {
        name: '',
        description: '',
        type: null,
        field: null,
        block: null,
        device: null,
        payloadKey: [],
        source: null,
        timeWindow: null,
        interval: null,
        frame: null,
        multipleDevices: [],
      },
      properties: null,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      descriptionRules: [
        (v) => v == null || (v != null && v.length <= 100) || 'Description must be less than 100 characters',
      ],
      typeRules: [
        (v) => !!v || 'Type is required',
      ],
      sourceRules: [
        (v) => !!v || 'Source is required',
      ],
      deviceRules: [
        // check if device has required payload
        // example: energy need stack_count, switch need valve
        () => {
          if (!this.deviceHasRequiredPayload) {
            return 'This device is missing required payload';
          }
          return true;
        },
        (v) => !!v || 'Device is required',
      ],
      payloadKeyRules: [
        // energy doesnt need payload key
        (v) => {
          if (this.form.source !== 'energy') {
            return v.length > 0 || 'Payload Key is required';
          }
          return true;
        },
      ],
      timeWindowRules: [
        // timewindow required in uplink and energy
        (v) => {
          if (this.form.source === 'uplink' || this.form.source === 'energy') {
            return !!v || 'Time window is required';
          }
          return true;
        },
      ],
      intervalRules: [
        // interval required in energy
        (v) => {
          if (this.form.source === 'energy') {
            return !!v || 'Time interval is required';
          }
          return true;
        },
      ],
      frameRules: [
        // interval required in energy, but not in chart
        (v) => {
          if (this.form.source === 'energy' && this.form.type !== 'chart') {
            return !!v || 'Time interval is required';
          }
          return true;
        },
      ],
    };
  },

  computed: {
    needMultiplePayload() {
      return this.form.type === 'table';
    },

    selectAllPayloadKey() {
      if (this.payloadKeys == null) {
        return false;
      }
      return this.form.payloadKey.length === this.payloadKeys.length;
    },

    selectSomePayloadKey() {
      return this.form.payloadKey.length > 0 && !this.selectAllPayloadKey;
    },

    selectAllIcon() {
      if (this.selectAllPayloadKey) return 'mdi-close-box';
      if (this.selectSomePayloadKey) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },

    deviceHasRequiredPayload() {
      // check if device need to have something required
      // energy need stacks_count > 0
      // switch need valve payload key
      if (this.form.source === 'energy' && this.form.device) {
        const device = this.devices.find((d) => d.id === this.form.device);
        return !!device.stacks_count;
      }
      return true;
    },
  },

  watch: {
    dialog(val) {
      // Reset errors when open modal dialog
      this.$store.commit(types.ERROR, null);

      if (val && !isEmptyObject(this.item)) {
        this.isEdit = true;
        this.loadData();
        setTimeout(() => {
          this.$refs.form.validate();
        }, 0);
      } else {
        this.isEdit = false;
        this.form = { ...this.defaultForm };
      }

      if (!val) {
        this.closeDialog();
      }
    },

    'form.type': function formTypeChanged(type) {
      // reset properties when change type
      // this.properties = null;
      this.allSources = [];

      //   if (type == 'switch') {
      //     this.form.payloadKey = null;
      //   }

      //   if (type == 'map') {
      //     // if empty, add one device form
      //     if (this.form.multipleDevices.length == 0) {
      //       this.attachWidgetDevice();
      //     }
      //     this.isMultipleDevices = true;
      //   } else {
      //     this.isMultipleDevices = false;
      //   }

      // specify data source options for each widget type
      let sources = [];
      if (type === 'gauge' || type === 'value') {
        sources = ['energy', 'device'];
      } else if (type === 'chart') {
        sources = ['uplink', 'energy'];
      } else if (type === 'switch' || type === 'progress' || type === 'table' || type === 'map') {
        sources = ['device'];
      }

      // populate source to options allSources
      if (sources.length) {
        this.allSources = sources.map((i) => {
          if (i === 'energy') {
            return {
              text: 'Energy (Counted value)',
              value: 'energy',
            };
          } if (i === 'device') {
            return {
              text: 'Device (Latest payload)',
              value: 'device',
            };
          } if (i === 'uplink') {
            return {
              text: 'Uplink (Timeseries)',
              value: 'uplink',
            };
          }
          return {};
        });
      }

      // set payload key to automatically
      //   this.setPayloadKeyAutomatically();
    },

    'form.device': function formDeviceChanged(device) {
      if (device) {
        this.getPayloadKeys(device);
      }
    },

    'form.frame': function formFrameChanged(frame) {
      if (frame === 'day') {
        this.form.timeWindow = 'today';
        this.form.interval = 'daily';
      } else if (frame === 'month') {
        this.form.timeWindow = 'first day of';
        this.form.interval = 'monthly';
      } else if (frame === 'hour') {
        this.form.timeWindow = 'today';
        this.form.interval = 'hourly';
      }
    },
  },

  created() {
    this.getDevice();
  },

  methods: {
    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        if (this.$refs.form) this.$refs.form.resetValidation();
        this.form = { ...this.defaultForm };
        this.properties = null;
        this.isEdit = false;
      }, 300);
    },

    saveWidget() {
      // validate form
      this.$refs.form.validate();
      if (!this.formValidity) return;

      const newWidget = this.generateWidgetFormat();
      if (this.isEdit) {
        this.updateWidget(newWidget);
      } else {
        this.addWidget(newWidget);
      }
      this.dialog = false;
    },

    addWidget(widgetData) {
      this.$emit('add-widget', widgetData);
    },

    updateWidget(widgetData) {
      // use prior layout, dont use auto generate layout
      this.$set(widgetData, 'layout', this.item.layout);
      this.$emit('update-widget', widgetData);
    },

    generateWidgetFormat() {
      let uniqueKeys = null;
      if (this.form.source === 'device') {
        if (this.isMultipleDevices) {
          // if type == map, using multiple device
          // map widget
          uniqueKeys = {
            data: {
              //   device: this.form.multipleDevices.map((item) => ({
              //     field_id: item.field,
              //     block_id: item.block,
              //     id: item.device,
              //     icon: item.icon,
              //     payload_key: item.payload_key || null,
              //     low_threshold: item.low_threshold || null,
              //     high_threshold: item.high_threshold || null,
              //     value_direction: item.value_direction || 'up',
              //   })),
            },
          };
        } else {
          // other than map, using single device
          // latest value widget
          uniqueKeys = {
            data: {
              device: {
                id: this.form.device,
              },
              payload_keys: Array.isArray(this.form.payloadKey) ? this.form.payloadKey.map((v) => v) : [this.form.payloadKey],
            },
          };
        }
      } else if (this.form.source === 'uplink') {
        // timerange widget
        uniqueKeys = {
          data: {
            uplink: {
              id: this.form.device,
              start_date: this.form.timeWindow,
              end_date: 'today',
              time_window: this.form.timeWindow,
            },
            payload_keys: [
              this.form.payloadKey,
            ],
          },
        };
      } else if (this.form.source === 'energy') {
        // energy widget
        uniqueKeys = {
          data: {
            energy: {
              id: this.form.device,
              start_date: this.form.timeWindow,
              end_date: 'now',
              time_window: this.form.timeWindow,
              interval: this.form.interval,
              frame: this.form.frame,
            },
            payload_keys: this.form.payloadKey,
          },
        };
      }

      const commonKeys = {
        data: {
          source: this.form.source,
        },
        layout: {
          x: 0, y: 0, w: 12, h: 2, i: this.index.toString(),
        },
        panel: {
          title: this.form.name,
          description: this.form.description,
        },
        type: this.form.type || 'gauge',
        properties: this.properties,
      };

      return merge(commonKeys, uniqueKeys);
    },

    async getDevice() {
      try {
        const res = await this.$api.getDevices();
        this.devices = res.data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    getPayloadKeys(deviceId) {
      const device = this.devices.find((d) => d.id === deviceId);
      if (!device.latest_payload) {
        this.payloadKeys = [];
      } else {
        this.payloadKeys = Object.keys(device.latest_payload)
          .filter((item) => item !== 'datetime')
          // map for select
          .map((item) => ({
            id: item,
            name: underscoreToSpace(item),
          }));

        // set payload key to automatically
        // when get payload keys
        // this.setPayloadKeyAutomatically();
      }
    },

    loadData() {
      if (!isEmptyObject(this.item)) {
        const {
          type, data, panel, properties,
        } = this.item;

        // panel
        this.form.name = panel.title;
        this.form.description = panel.description;

        // type
        this.form.type = type;

        // data
        this.form.source = data.source;
        if (data.source) {
          this.form.device = data[data.source].id;
        }
        if (this.needMultiplePayload) {
          this.form.payloadKey = [...data.payload_keys];
        } else {
          this.form.payloadKey = data.payload_keys && data.payload_keys.length === 1 ? data.payload_keys[0] : data.payload_keys;
        }

        // time
        if (data.source === 'uplink' || data.source === 'energy') {
          this.form.timeWindow = data[data.source].time_window;
        }
        if (data.source === 'energy') {
          this.form.interval = data.energy.interval;
        }
        if (data.source === 'energy' && type !== 'chart') {
          this.form.frame = data.energy.frame;
        }

        // properties
        this.properties = properties;
      }
    },

    generateComponent(widgetType) {
      switch (widgetType) {
        case 'gauge':
          return 'GaugeProperties';
        // case 'progress':
        //   return 'ProgressProperties';
        case 'value':
          return 'ValueProperties';
        // case 'counter':
        //   return 'CounterProperties';
        // case 'switch':
        //   return 'SwitchProperties';
        // case 'chart':
        //   return 'ChartProperties';
        // case 'map':
        //   return 'MapProperties';
        default:
          return 'NullProperties';
      }
    },

    resetToDefaultProperties() {
      // reset widget properties to default
      EventBus.$emit('reset-default-properties');
      this.$toast.success('Widget property has been reset to default');
    },

    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.selectAllPayloadKey) {
          this.form.payloadKey = [];
        } else {
          this.form.payloadKey = this.payloadKeys.slice().map((pk) => pk.id);
        }
      });
    },
  },

};
</script>
