<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
        <v-spacer />
        <v-icon @click="closeDialog">
          mdi-close
        </v-icon>
      </v-card-title>

      <ErrorAlert />

      <v-card-text class="pt-5 pb-0">
        <v-form
          ref="form"
          v-model="formValidity"
        >
          <v-text-field
            v-model="form.name"
            :rules="nameRules"
            :disabled="loading"
            :counter="30"
            outlined
            dense
            label="Name"
            @keypress.enter="saveItem"
          />
          <v-textarea
            v-model="form.description"
            :rules="descriptionRules"
            :disabled="loading"
            :counter="100"
            outlined
            dense
            label="Description"
            auto-grow
          />
          <template v-if="isSuperadmin">
            <v-autocomplete
              v-model="form.organization_id"
              :items="organizations"
              :disabled="loading"
              outlined
              dense
              clearable
              item-text="name"
              item-value="id"
              label="Organization"
            />
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          class="primary"
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import FormMixin from '../../mixins/FormMixin';

export default {
  name: 'DashboardForm',
  mixins: [FormMixin],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      form: {
        name: '',
        organization_id: null,
        description: '',
      },
      defaultForm: {
        name: '',
        organization_id: null,
        description: '',
      },
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      descriptionRules: [
        (v) => v == null || (v != null && v.length <= 100) || 'Description must be less than 100 characters',
      ],
    };
  },

  computed: {
    ...mapGetters(['organizations']),

    isSuperadmin() {
      return this.$store.getters.user.is_superadmin;
    },
  },

  watch: {
    dialog(bool) {
      if (bool) {
        // load data if dialog opened
        // only for superadmin
        if (this.isSuperadmin) {
          this.$store.dispatch('getOrganizations');
        }
      }
    },
  },

  methods: {
    addData() {
      this.removeUnauthorizedProperty();
      return this.$api.addDashboard(this.formData);
    },

    updateData() {
      this.removeUnauthorizedProperty();
      const { widgets, ...selectedData } = this.formData;

      return this.$api.updateDashboard({
        id: this.form.id,
        ...selectedData,
      });
    },

    removeUnauthorizedProperty() {
      // key only for superadmin authority
      if (!this.isSuperadmin) {
        delete this.form.organization_id;
      }
    },
  },
};
</script>
