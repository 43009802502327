<template>
  <div class="mt-3">
    <v-scale-transition
      leave-absolute
      hide-on-leave
    >
      <div v-if="!loadingWrapper">
        <div
          v-if="(!dashboardDetail.widgets || !dashboardDetail.widgets.length)"
          class="d-flex flex-column justify-center align-center"
          style="height:60vh"
        >
          <div class="d-block">
            <v-icon size="80">
              mdi-monitor
            </v-icon>
          </div>
          <div class="d-block title font-italic font-weight-light">
            Your dashboard is empty
          </div>
          <v-btn
            v-if="isAdmin"
            color="primary"
            class="ma-3"
            small
            @click="addFirstWidget()"
          >
            Add Widget
          </v-btn>
        </div>
        <div v-else>
          <grid-layout
            ref="gridLayout"
            style="margin:-10px;"
            :layout.sync="layout"
            :col-num="12"
            :row-height="70"
            :is-draggable="editMode && gridDraggable"
            :is-resizable="editMode"
            :touch-action="editMode ? 'none' : 'auto'"
            :margin="[10, 10]"
            :responsive="isDashboardResponsive"
            :breakpoints="{ lg: 580, xs: 0 }"
          >
            <grid-item
              v-for="widget in widgets"
              :key="widget.layout.i"
              :x="widget.layout.x"
              :y="widget.layout.y"
              :w="widget.layout.w"
              :h="widget.layout.h"
              :i="widget.layout.i"
              :min-w="2"
              :max-w="12"
              :min-h="2"
            >
              <v-scale-transition>
                <BaseWidget
                  :type="widget.type"
                  :data="widget.data"
                  :layout="widget.layout"
                  :device-data="widget.deviceData? widget.deviceData.getData: {}"
                  :panel="widget.panel"
                  :properties="widget.properties"
                  :loading="loadingWidget"
                />
              </v-scale-transition>
              <!-- :device-data="Array.isArray(widget.deviceData)? widget.deviceData.map(i => i.getData) : widget.deviceData.getData" -->
              <!-- @resized="resizedEvent" -->
            </grid-item>
          </grid-layout>
        </div>
      </div>
    </v-scale-transition>
    <v-scale-transition
      leave-absolute
      hide-on-leave
    >
      <div
        v-if="loadingWrapper"
        class="text-center"
      >
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        />
        <div class="my-3">
          Loading widgets
        </div>
      </div>
    </v-scale-transition>

    <DashboardDetailWidgetForm
      ref="DashboardDetailWidgetForm"
      :index="index"
      :item="selectedWidget"
      :dashboard-detail="dashboardDetail"
      @add-widget="addWidget"
      @update-widget="updateWidget"
    />
  </div>
</template>

<script>
import EventBus from '@/EventBus';
import { format } from 'date-fns';
import { handleError } from '@/utils/utils';
import VueGridLayout from 'vue-grid-layout';
import * as types from '@/store/mutation-types';
import { mapGetters } from 'vuex';
import DashboardDetailWidgetForm from './DashboardDetailWidgetForm.vue';
import BaseWidget from './widgets/BaseWidget.vue';
import Echo from '../../services/websocket';

export default {
  name: 'DashboardDetailWidget',
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    DashboardDetailWidgetForm,
    BaseWidget,
  },
  props: {
    dashboardDetail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      layout: [],
      index: 0,
      isDashboardResponsive: true,
      gridDraggable: true,
      loadingWrapper: true,
      loadingWidget: true,
      selectedWidget: {},
      deviceDataStore: [],
    };
  },

  computed: {
    ...mapGetters(['user']),
    isAdmin() {
      return this.user.is_superadmin || this.user.is_organization_admin;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    originalLayout() {
      return this.$refs.gridLayout.originalLayout;
    },

    editMode: {
      // sync from vuex store
      get() {
        return this.$store.state.dashboard.dashboardEditMode;
      },
      set(newValue) {
        this.$store.commit(types.TOGGLE_EDIT_MODE, newValue);
      },
    },

    widgets() {
      return this.dashboardDetail.widgets.map((item, index) => {
        // update layout data for every widget, to follow this.layout
        this.$set(item, 'layout', this.layout[index]);

        // fallback value if data provided is not valid
        let findFromStore = {
          getData: {},
          id: null,
        };

        const { data } = item;

        // find matching data from deviceDataStore
        if (data.source === 'device' && data.device) {
          const { id } = data.device;

          if (id) {
            if (Array.isArray(data.device)) {
              // if multiple device per widget
              findFromStore = [];
              this.deviceDataStore.forEach((endpoint) => {
                data.device.forEach((d) => {
                  if (endpoint.id === `${data.source}/${d.id}`) {
                    findFromStore.push(endpoint);
                  }
                });
              });
            } else {
              // if single device per widget
              // get device directly
              findFromStore = this.deviceDataStore.find((endpoint) => endpoint.id === `${data.source}/${id}`);
            }
          }
        } else if (data.source === 'uplink' && data.uplink) {
          const {
            id, start_date: startDate, end_date: endDate,
          } = data.uplink;

          if (id && startDate && endDate) {
            findFromStore = this.deviceDataStore.find((endpoint) => endpoint.id === `${data.source}/${id}/${startDate}/${endDate}`);
          }
        } else if (data.source === 'energy' && data.energy) {
          const {
            id, interval, start_date: startDate, end_date: endDate,
          } = data.energy;

          if (id && interval && startDate && endDate) {
            findFromStore = this.deviceDataStore.find((endpoint) => endpoint.id === `${data.source}/${id}/${interval}/${startDate}/${endDate}`);
          }
        }

        // set property to store data per widget
        this.$set(item, 'deviceData', findFromStore);
        return item;
      });
    },
  },

  watch: {
    editMode(status) {
      if (status === false) {
        this.updateDashboard();
      }
    },
  },

  async created() {
    this.fetchLayout();
    await this.fetchDataStore();
  },

  mounted() {
    EventBus.$on('add-widget', () => {
      this.selectedWidget = {};
      this.$refs.DashboardDetailWidgetForm.dialog = true;
    });

    EventBus.$on('edit-widget', (identifier) => {
      this.populateEditForm(identifier);
      this.$refs.DashboardDetailWidgetForm.dialog = true;
    });

    EventBus.$on('remove-widget', (identifier) => {
      this.removeWidget(identifier);
    });
  },

  beforeDestroy() {
    EventBus.$off('add-widget');
    EventBus.$off('edit-widget');
    EventBus.$off('remove-widget');
    this.unsubscribePayload();
  },

  methods: {
    async fetchDataStore() {
      this.loadingWidget = true;
      const promises = this.getUniqueEndpoint().map(async (item) => {
        const splitItem = item.split('/');
        const properties = { source: splitItem[0], deviceId: splitItem[1] };
        if (properties.source === 'uplink') {
          properties.startDate = splitItem[2];
          properties.endDate = splitItem[3];
        } else if (properties.source === 'energy') {
          properties.interval = splitItem[2];
          properties.startDate = splitItem[3];
          properties.endDate = splitItem[4];
        }

        return { id: item, getData: await this.getDeviceData(properties) };
      });

      // wait all to complete fetch data
      this.deviceDataStore = await Promise.all(promises);
      this.loadingWrapper = false;
      this.loadingWidget = false;

      // trick to positioning dashboard grid properly
      // avoid unintended overflowwing
      // setTimeout(() => {
      //   this.dashboardWrapperWidth = 100;
      //   this.debugMode = false;
      // }, 0);

      // subscribe when fetch new data
      this.subscribePayload();
    },

    getUniqueEndpoint() {
      // return formatted unique endpoint e.g. 'device/48/2'
      const identifier = [];
      // extract to specific format for identifier
      this.dashboardDetail.widgets.forEach(({ data }) => {
        if (data.source === 'device' && data.device) {
          const { id } = data.device;

          if (id) {
            if (Array.isArray(data.device)) {
              // if multiple device per widget
              data.device.forEach((d) => {
                identifier.push(`${data.source}/${d.id}`);
              });
            } else {
              // if single device per widget
              identifier.push(`${data.source}/${id}`);
            }
          } else {
            console.error('Device data source not valid');
          }
        } else if (data.source === 'uplink' && data.uplink) {
          const { id, start_date: startDate, end_date: endDate } = data.uplink;

          if (id && startDate && endDate) {
            identifier.push(`${data.source}/${id}/${startDate}/${endDate}`);
          } else {
            console.error('Uplink data source not valid');
          }
        } else if (data.source === 'energy' && data.energy) {
          const {
            id, interval, start_date: startDate, end_date: endDate,
          } = data.energy;

          if (id && interval && startDate && endDate) {
            identifier.push(`${data.source}/${id}/${interval}/${startDate}/${endDate}`);
          } else {
            console.error('Energy data source not valid');
          }
        }
      });
      // get unique array
      return [...new Set(identifier)];
    },

    async getDeviceData({
      source, deviceId, startDate, endDate, interval,
    }) {
      try {
        // get data using two different API, follow the data.source
        // return the result
        if (source === 'device') {
          const result = await this.$api.getDeviceDetail(deviceId);
          return result.data.data;
        }

        if (source === 'uplink') {
          const result = await this.$api.getUplinks({
            id: deviceId,
            start_date: startDate,
            end_date: endDate,
            load_payloads: 1,
          });

          const resultData = result.data.data;
          return {
            device_id: resultData.length ? resultData[0].device_id : null,
            payloads: resultData
            // reverse payload order
              .map((val) => val.payloads.reverse())
            // convert nested array to one-level array
              .reduce((a, b) => a.concat(b), []),
          };
        }

        if (source === 'energy') {
          // filter start date first date of current month
          if (startDate === 'first day of') {
            startDate = `first day of ${format(new Date(), 'MMMM')}`;
          }
          const result = await this.$api.getDeviceStacks(deviceId, {
            interval,
            start_date: startDate,
            end_date: endDate,
            timezone: 'Asia/Jakarta',
            page: 1,
            per_page: 100,
          });

          const resultData = result.data.data;
          return {
            device_id: resultData.length && resultData[0].device ? resultData[0].device.device_id : null,
            payloads: resultData,
          };
        }

        return {};
      } catch (error) {
        console.error(`Failed fetch field devices source:${source} device:${deviceId}. ${error}`);
        // if errors happened, return the string message
        return error.response.data.message;
      }
    },

    fetchLayout() {
      // trick to positioning dashboard grid properly
      // avoid unintended overflowwing
      // setTimeout(() => {
      //   this.dashboardWrapperWidth = 50;
      // }, 0);

      if (!this.dashboardDetail.widgets || !this.dashboardDetail.widgets.length) {
        // reset value, when widget null
        this.dashboardDetail.widgets = [];
        this.layout = [];
        this.index = 0;
      } else {
        // create layout from widgets layout data
        this.layout = this.dashboardDetail.widgets.map((widget) => widget.layout);
        // return largest index of layout
        const largestIndex = Math.max(...this.layout.map((val) => Number(val.i)));
        this.index = largestIndex + 1;
      }
    },

    addWidget(widgetData) {
      if (!this.dashboardDetail.widgets.length) this.dashboardDetail.widgets = [];
      this.dashboardDetail.widgets.push(widgetData);

      this.fetchLayout();
      this.fetchDataStore();
      this.updateDashboard();
    },

    updateWidget(widgetData) {
      this.dashboardDetail.widgets = this.dashboardDetail.widgets.map((item) => {
        if (item.layout.i == widgetData.layout.i) {
          // widgetData.layout = { ...item.layout }; // using raw layout from db
          return widgetData;
        }
        return item;
      });

      this.fetchLayout();
      this.fetchDataStore();
      this.updateDashboard();
    },

    populateEditForm(widgetId, presetDeviceId = null) {
      // set preset device, to auto select tab in device form
      // set null if not available
      // this.presetDeviceId = presetDeviceId;
      this.selectedWidget = this.dashboardDetail.widgets.find((w) => w.layout.i === widgetId);
    },

    removeWidget(widgetId) {
      // remove widget using widgetId on layout properties
      const widget = this.dashboardDetail.widgets.find((w) => w.layout.i == widgetId);
      const findWidget = this.dashboardDetail.widgets.indexOf(widget);
      this.dashboardDetail.widgets.splice(findWidget, 1);

      this.fetchLayout();
      this.fetchDataStore();
      this.updateDashboard();

      // reset width to normal after remove widget
      // setTimeout(() => {
      //   this.dashboardWrapperWidth = 100;
      // }, 0);
    },

    updateDashboard() {
      // set to no-responsive when in small-medium to avoid breaking layout
      // used for saving layout only
      //   if (window.matchMedia('(max-width: 768px)').matches) {
      //     this.isDashboardResponsive = false;
      //   }
      // set dashboard to discard reload, because who is updating is the event sender
      //   this.isSendingDashboardEvent = true;

      this.$nextTick(async () => {
        // throw away unused properties when update data
        const { widgets, id } = this.dashboardDetail;
        // copy object to avoid mutate original data, and throw away deviceData
        // avoid upload deviceData to server

        const sanitizeWidgets = [...widgets].map((w, index) => ({
          data: w.data,
          layout: this.isMobile ? this.originalLayout[index] : w.layout,
          panel: w.panel,
          properties: w.properties,
          type: w.type,
        }));

        // const sanitizeWidgets = JSON.parse(JSON.stringify(sanitizeObject)).widgets
        //   .map((widget) => {
        //     const { deviceData, ...restData } = widget;
        //     return restData;
        //   });

        // create new object to upload config
        // const uploadObj = {
        //   name: sanitizeObject.name,
        //   description: sanitizeObject.description,
        //   widgets: sanitizeWidgets,
        // };

        try {
          await this.$api.updateDashboard({
            id,
            widgets: sanitizeWidgets,
          });
          this.$toast.success('Success Update Dashboard');
        } catch (error) {
          handleError(error);
          this.$toast.error('Failed Update Dashboard');
        }

        // set back to responsive after saved
        // this.isDashboardResponsive = true;
      });
    },

    addFirstWidget() {
      this.$refs.DashboardDetailWidgetForm.dialog = true;
    },

    // getDeviceChannel(dataStore) {
    //   // device identifier >>> device/49
    //   // uplink identifier >>> uplink/49/3 days ago/today
    //   const identifier = dataStore.id.split('/');
    //   const source = identifier[0];
    //   let deviceId = null;
    //   // get device_id
    //   if (source === 'device' || source === 'uplink') {
    //     deviceId = dataStore.getData.device_id;
    //   } else {
    //     console.error('Error get device channel');
    //   }

    //   return `device.${deviceId}`;
    // },

    subscribePayload() {
      // loop all device to subscribe
      this.deviceDataStore.forEach((dataStore, idx) => {
        const source = dataStore.id.split('/')[0];
        const deviceChannel = `device.${dataStore.getData.device_id}`;

        Echo.subscribeChannel(deviceChannel)
          // listen new payload uplinks
          .listen('UplinkReceived', (data) => {
            if (source === 'device') {
              // get rid of common payload key
              this.deviceDataStore[idx].getData.latest_payload = data.payload;
            } else if (source === 'uplink') {
              this.deviceDataStore[idx].getData.payloads.unshift(data.payload);
            }
            // set latest uplink date
            // this.widgetLatestDatetime = new Date();
          });
        // listen busy state
        // .listen('DeviceIsBusy', (data) => {
        //   if (source == 'device') {
        //     dataStore.getData.is_busy = data.is_busy;
        //   }
        // });
      });
    },

    unsubscribePayload() {
      // loop all device to unsubscribe
      this.deviceDataStore.forEach((dataStore) => {
        const deviceChannel = `device.${dataStore.getData.device_id}`;
        // leave channel
        Echo.leaveChannel(deviceChannel);
      });
    },
  },
};
</script>
