<template>
  <div>
    <v-form
      ref="form"
      v-model="formValidity"
    >
      <v-card class="mb-5">
        <v-card-text>
          <v-text-field
            v-model.number="form.min"
            type="number"
            :rules="minRules"
            outlined
            dense
            label="Min value"
          />
          <v-color-picker
            v-model="form.low_color"
            hide-canvas
            hide-mode-switch
            mode="hexa"
            flat
            width="100%"
          />
        </v-card-text>
      </v-card>
      <v-card class="mb-5">
        <v-card-text>
          <v-text-field
            v-model.number="form.max"
            :rules="maxRules"
            type="number"
            outlined
            dense
            label="Max value"
          />
          <v-color-picker
            v-model="form.high_color"
            hide-canvas
            hide-mode-switch
            mode="hexa"
            flat
            width="100%"
          />
        </v-card-text>
      </v-card>
      <v-text-field
        v-model.number="form.interval"
        :rules="intervalRules"
        type="number"
        outlined
        dense
        label="Tick Interval"
      />
      <v-text-field
        v-model.number="form.size"
        :rules="sizeRules"
        type="number"
        outlined
        dense
        label="Text Size"
      />
      <v-checkbox
        v-model="form.show_value_unit"
        label="Show Value Unit"
      />
    </v-form>
  </div>
</template>

<script>
import WidgetPropertiesMixin from '../../../mixins/WidgetPropertiesMixin';

export default {
  name: 'GaugeProperties',
  mixins: [WidgetPropertiesMixin],

  data() {
    return {
      defaultProperties: {
        min: 0,
        max: 150,
        interval: 10,
        size: 20,
        show_value_unit: true,
        low_color: '#FF0000',
        high_color: '#9400D3',
      },
      form: {
        min: 0,
        max: 150,
        interval: 10,
        size: 20,
        show_value_unit: true,
        low_color: '#FF0000',
        high_color: '#9400D3',
      },
      minRules: [
        (v) => v === 0 || (!!v && v !== 0) || 'Minimal value is required',
      ],
      maxRules: [
        (v) => !!v || 'Maximal value is required',
      ],
      intervalRules: [
        (v) => !!v || 'Interval is required',
      ],
      sizeRules: [
        (v) => !!v || 'Size is required',
      ],
      lowColorRules: [
        (v) => !!v || 'Low Color is required',
      ],
      highColorRules: [
        (v) => !!v || 'High Color is required',
      ],
    };
  },

  watch: {
    'form.max': function maxChanged(max) {
      this.form.interval = Number(max) / 10;
    },
  },
};
</script>
