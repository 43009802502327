<template>
  <div>
    <v-form
      ref="form"
      v-model="formValidity"
    >
      <v-text-field
        v-model="form.size"
        type="text"
        outlined
        dense
        label="Text Size"
      />
      <v-checkbox
        v-model="form.show_value_unit"
        label="Show Value Unit"
      />
    </v-form>
  </div>
</template>

<script>
import WidgetPropertiesMixin from '../../../mixins/WidgetPropertiesMixin';

export default {
  name: 'GaugeProperties',
  mixins: [WidgetPropertiesMixin],

  data() {
    return {
      defaultProperties: {
        size: 20,
        show_value_unit: true,
      },
      form: {
        size: 20,
        show_value_unit: true,
      },
    };
  },
};
</script>
