<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card>
        <v-skeleton-loader
          :loading="loading"
          type="article"
        >
          <v-card-text>
            <div class="d-flex justify-space-between">
              <div>
                <p class="headline text--primary mb-0">
                  {{ dashboardDetail.name }}
                </p>
                <div v-if="dashboardDetail.description">
                  {{ dashboardDetail.description }}
                </div>
              </div>
              <v-btn
                v-if="isAdmin"
                icon
                @click="$refs.formModal.dialog=true"
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-skeleton-loader>
        <v-divider />
        <v-card-text v-if="dashboardDetail.widgets && dashboardDetail.widgets.length && isAdmin">
          <div class="d-flex justify-space-between align-center">
            <v-switch
              v-if="isAdmin"
              v-model="editMode"
              label="Edit Mode"
              dense
              hide-details
              class="ma-1 pa-0"
            />
            <v-slide-x-reverse-transition>
              <v-btn
                v-if="editMode"
                color="primary"
                small
                @click="openWidgetForm()"
              >
                Add widget
              </v-btn>
            </v-slide-x-reverse-transition>
          </div>
        </v-card-text>
      </v-card>

      <DashboardForm
        ref="formModal"
        :item="dashboardDetail"
        @reload-data="getDashboardDetail()"
      />

      <DashboardDetailWidget
        v-if="!isEmptyObject(dashboardDetail)"
        ref="DashboardDetailWidget"
        :dashboard-detail="dashboardDetail"
        @reload-data="getDashboardDetail()"
      />

      <!-- <v-fab-transition>
        <v-btn
          v-if="editMode"
          v-model="fab"
          color="thingsnesia_base"
          dark
          fab
          fixed
          bottom
          right
          style="right:30px;bottom:30px"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-fab-transition> -->
    </v-col>
  </v-row>
</template>

<script>
import EventBus from '@/EventBus';
import { mapGetters } from 'vuex';
import { handleDetailError, isEmptyObject } from '../../utils/utils';
import DashboardForm from './DashboardForm.vue';
import DashboardDetailWidget from './DashboardDetailWidget.vue';
import * as types from '../../store/mutation-types';

export default {
  name: 'DashboardDetail',
  components: { DashboardForm, DashboardDetailWidget },

  data() {
    return {
      loading: false,
      dashboardDetail: {},
      //   fab: false,
    };
  },

  computed: {
    ...mapGetters(['user']),
    dashboardId() {
      return Number(this.$route.params.id);
    },

    isAdmin() {
      return this.user.is_superadmin || this.user.is_organization_admin;
    },

    editMode: {
      // sync from vuex store
      get() {
        return this.$store.state.dashboard.dashboardEditMode;
      },
      set(newValue) {
        this.$store.commit(types.TOGGLE_EDIT_MODE, newValue);
      },
    },
  },

  created() {
    this.getDashboardDetail();
  },

  beforeDestroy() {
    this.editMode = false;
  },

  methods: {
    isEmptyObject,

    async getDashboardDetail() {
      this.loading = true;
      try {
        const result = await this.$api.getDashboardDetail(this.dashboardId);
        this.dashboardDetail = result.data.data;
      } catch (error) {
        handleDetailError(error, this);
      }
      this.loading = false;
    },

    openWidgetForm() {
      EventBus.$emit('add-widget');
    },
  },
};
</script>
