<template>
  <v-card
    style="height:100%"
  >
    <div class="d-flex justify-space-between align-top py-1 px-2">
      <v-card-title class="body-1 pa-0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">   {{ panel.title }}</span>
          </template>
          <em>{{ panel.description || 'No Description' }}</em>
        </v-tooltip>
      </v-card-title>
      <div style="white-space: pre;">
        <v-menu
          :close-on-content-click="false"
          origin="top right"
          bottom
          left
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              x-small
              v-on="on"
            >
              <v-icon> mdi-information</v-icon>
            </v-btn>
          </template>

          <v-card>
            <DeviceDetailStatus
              :last-uplink-time="lastUplinkTime"
              :loading="false"
            />
            <v-divider />

            <v-list>
              <v-list-item
                :to="generateDevicePath()"
                target="_blank"
              >
                <v-list-item-content>
                  <v-list-item-title>Open Device</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-open-in-new</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <span v-if="editMode">
          <v-btn
            class="ml-2"
            x-small
            icon
            @click="editWidget"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            class="ml-2"
            x-small
            icon
            color="error"
            @click="removeWidget"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>
      </div>
    </div>
    <v-card-text
      class="widget-body"
    >
      <component
        :is="generateComponent(type)"
        v-if="!isPropertiesError && !isDataError"
        :type="type"
        :data="data"
        :layout="layout"
        :device-data="deviceData"
        :panel="panel"
        :properties="properties"
      />
      <v-alert
        v-if="isPropertiesError"
        outlined
        type="error"
        border="left"
      >
        Widget Properties Error. Update this widget properties setting to resolve this issue.
      </v-alert>
      <v-alert
        v-if="isDataError && !loading"
        outlined
        type="warning"
        border="left"
      >
        Data could not be retrieved from the database or Data not found. Update this widget data setting.
      </v-alert>
      <v-alert
        v-if="loading && isDataError"
        outlined
        type="info"
        border="left"
      >
        Loading data...
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import EventBus from '@/EventBus';
import DeviceDetailStatus from '../../device/DeviceDetailStatus.vue';
import { isEmptyObject } from '../../../utils/utils';
import GaugeProperties from '../properties/GaugeProperties.vue';
import ValueProperties from '../properties/ValueProperties.vue';

export default {
  name: 'BaseWidget',
  components: {
    DeviceDetailStatus,
    GaugeWidget: () => import('./GaugeWidget'),
    ChartWidget: () => import('./ChartWidget'),
    ValueWidget: () => import('./ValueWidget'),
    TableWidget: () => import('./TableWidget'),
    NullWidget: () => import('./NullWidget'),
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    layout: {
      type: Object,
      required: true,
    },
    deviceData: {
      type: [Object, Array, String],
      required: true,
    },
    panel: {
      type: Object,
      required: true,
    },
    properties: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      errorData: null,
    };
  },

  computed: {
    editMode() {
      return this.$store.state.dashboard.dashboardEditMode;
    },

    lastUplinkTime() {
      const { source, device } = this.data;
      if (Array.isArray(device)) {
        // if multiple device, dont get latest datetime
        return null;
      }

      // if source device, load latest_payload
      // if source uplink or energy, load first array
      if (!this.errorData && typeof this.deviceData !== 'string') {
        if (source === 'device') {
          return (this.deviceData.latest_payload ? this.deviceData.latest_payload.datetime : this.deviceData.last_uplink_at) || null;
        } if (source === 'uplink') {
          return this.deviceData.payloads && this.deviceData.payloads.length ? this.deviceData.payloads[0].datetime : null;
        } if (source === 'energy') {
          return this.deviceData.payloads && this.deviceData.payloads.length ? this.deviceData.payloads[0].device?.last_uplink_at : null;
        }
        return null;
      }
      return null;
    },

    isDataError() {
      if (typeof (this.deviceData) === 'object') {
        return isEmptyObject(this.deviceData);
      }
      if (Array.isArray(this.deviceData)) {
        return this.deviceData.length === 0;
      }
      return !this.deviceData;
    },

    isPropertiesError() {
      if (this.getDefaultProperties(this.type) == null) {
        // if widget doesnt have properties, dont set error
        return false;
      }

      if (this.properties) {
        let flag = false;
        // arr of properties
        const defaultProperties = this.getDefaultProperties(this.type);

        // check if widget property is missing
        defaultProperties.forEach((prop) => {
          if (this.properties[prop] === undefined && this.properties[prop] !== 0) {
            // if there is a property missing, set error flag to true
            flag = true;
          }
        });
        return flag;
      }

      // when widget need properties, but no properties provided
      return true;
    },
  },

  methods: {
    editWidget() {
      // send event remove widget
      EventBus.$emit('edit-widget', this.layout.i);
    },

    async removeWidget() {
      // send event edit widget
      if (await this.$root.$confirm('Delete', 'Are you sure want to delete this widget?', { color: 'error' })) {
        EventBus.$emit('remove-widget', this.layout.i);
      }
    },

    generateDevicePath() {
      if (!this.data) return null;
      if (this.data.source === 'device' && Array.isArray(this.data.device)) {
        return null;
      }
      if (this.data.source) {
        return {
          path: `/devices/${this.data[this.data.source].id}`,
        };
      }
      return null;
    },

    generateComponent(widgetType) {
      switch (widgetType) {
        case 'gauge':
          return 'GaugeWidget';
        case 'chart':
          return 'ChartWidget';
        case 'value':
          return 'ValueWidget';
        case 'table':
          return 'TableWidget';
        // case 'switch':
        //   return 'SwitchWidget';
        // case 'counter':
        //   return 'CounterWidget';
        // case 'progress':
        //   return 'ProgressWidget';
        // case 'map':
        //   return 'MapWidget';
        default:
          return 'NullWidget';
      }
    },

    getDefaultProperties(type) {
      let defaultProperties;
      switch (type) {
        case 'gauge':
          defaultProperties = GaugeProperties.data().defaultProperties;
          break;
        case 'value':
          defaultProperties = ValueProperties.data().defaultProperties;
          break;

        default:
          defaultProperties = {};
      }

      // if widget doesnt have property, return null
      if (isEmptyObject(defaultProperties)) return null;
      return Object.keys(defaultProperties);
    },
  },
};
</script>

<style lang="css" scoped>
.widget-body {
  position:absolute;
  top: 25px;
  height: calc(100% - 25px);
  /* z-index: -1; */
  overflow: auto;
}
</style>
