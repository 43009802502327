import EventBus from '@/EventBus';
import { isEmptyObject } from '../utils/utils';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    properties: {
      default() {
        return null;
      },
    },
  },

  data() {
    return {
      formValidity: true,
    };
  },

  watch: {
    form: {
      // update properties when form change
      handler(val) {
        this.$emit('update:properties', !isEmptyObject(val) ? val : null);

        // auto validate properties
        this.$refs.form.validate();
      },
      deep: true,
    },
    // $v: {
    //   // update error when data change
    //   handler() {
    //     this.$emit('update:error-properties', this.$v.$invalid);
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },

  mounted() {
    setTimeout(() => {
      if (this.properties) {
        this.populatePropertiesData();
      } else {
        // first load, set properties from default
        this.$emit('update:properties', this.defaultProperties || null);
      }
      // {
      //   // this.$v.$touch();
      //   // when edit widget
      //   // fetch data from dashboard in each properties
      //   this.populatePropertiesData();
      //   // first load, set properties from data
      //   // this.$emit('update:properties', this.properties);
      // } else {
      //   // first load, set properties from default
      //   // this.$emit('update:properties', !isEmptyObject(this.$data) || null);
      // }
    }, 0);

    EventBus.$on('reset-default-properties', () => {
      this.form = { ...this.defaultProperties };
    });
  },

  beforeDestroy() {
    EventBus.$off('reset-default-properties');
  },

  methods: {
    populatePropertiesData() {
      if (!this.properties || !this.form) return;

      Object.keys(this.form).forEach((p) => {
        this.form[p] = this.properties[p];
      });
    },
  },
};
